<template>
  <div class="mt-10">
    <v-footer
      tile
      flat
      class="font-weight-medium shadow-md -mt-20"
      :color="footerBar"
      :dark="$vuetify.theme.dark"
      :inset="true"
      v-if="hiddenAppBar"
    >
      <v-container>
        <v-row class="mt-20" dense>
          <v-col cols="12" md="4">
            <h6 class="white--text mb-0">Sobre nós</h6>
            <p class="body-2 grey--text text--lighten-2">
              {{ texts.footerDescription }}
            </p>
            <div class="align-center pb-2">
              <p class="grey--text font-weight-bold text-15 text--lighten-2 mb-0">
                {{ legalInfo.fantasyName }}
              </p>
              <span class="grey--text text--lighten-2 text-12">
                {{ legalInfo.document }}
              </span>
            </div>
          </v-col>
          <v-col cols="12" md="4">
            <h6 class="white--text">Explorar</h6>
            <div class="mb-2">
              <router-link to="/app" class="grey--text text--lighten-2">
                Home
              </router-link>
            </div>
            <div class="mb-2">
              <router-link to="/shop" class="grey--text text--lighten-2">
                Eventos
              </router-link>
            </div>
            <!-- Termos & Condições -->
            <div class="mb-2">
              <router-link to="/terms/contract" class="grey--text text--lighten-2">
                Termos & Condições
              </router-link>
            </div>
            <div class="mb-2">
              <router-link to="/terms/privacy" class="grey--text text--lighten-2">
                Politica de Privacidade
              </router-link>
            </div>
          </v-col>
          <v-col cols="12" md="4">
            <div class="mb-5">
              <h6 class="white--text mb-1">Entre em contato com a gente!</h6>
              <div class="d-flex flex-wrap mb-2" style="gap: 0.5rem">
                <v-btn
                  target="_blank"
                  :href="`mailto://${email}`"
                  small
                  color="primary"
                >
                  <v-icon small left>mdi-email</v-icon>
                  Email
                </v-btn>
                <v-btn
                  target="_blank"
                  :href="`//wa.me/${formattedWhatsApp}`"
                  small
                  color="primary"
                >
                  <v-icon small left>mdi-whatsapp</v-icon>
                  Whatsapp
                </v-btn>
              </div>
            </div>
            <div>
              <h6 class="white--text mb-1">Nossas redes sociais!</h6>
              <div class="d-flex flex-wrap mb-2" style="gap: 0.5rem">
                <v-btn
                  v-for="(sn, i) in socialNetworks"
                  :key="i"
                  :href="socialNetworksItens[sn.type].urlFormatter(sn.url)"
                  small
                  depressed
                  target="_blank"
                  color="primary"
                >
                  <v-icon small left>
                    {{ socialNetworksItens[sn.type].icon }}
                  </v-icon>
                  <span>
                    {{ socialNetworksItens[sn.type].prefix }}{{ sn.url }}
                  </span>
                </v-btn>
              </div>
            </div>
          </v-col>
          <v-col cols="12">
            <div class="d-flex gap-2 align-center" v-if="!hidePoweredBy">
              <small class="grey--text text--lighten-2"> PoweredBy </small>
              <img
                style="width: 80px"
                src="/img/logo/voy-full-dark.png"
                alt=""
              />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </div>
</template>

<script>
import {
  texts,
  socialNetworks,
  hidePoweredBy,
  whatsapp,
  email,
  legalInfo,
  footerBar,
} from "@/themeConfig.js";

export default {
  name: "Footer",
  data: () => ({
    whatsapp,
    email,
    texts,
    socialNetworks,
    hidePoweredBy,
    legalInfo,
    footerBar,
    socialNetworksItens: {
      ig: {
        id: "ig",
        name: "Instagram",
        icon: "mdi-instagram",
        urlFormatter: (url) => `https://instagram.com/${url}`,
        prefix: "@",
        placeholder: "Digite o nome de usuário",
      },
      fb: {
        id: "fb",
        name: "Facebook",
        icon: "mdi-facebook",
        urlFormatter: (url) => `https://facebook.com/${url}`,
        prefix: "@",
        placeholder: "Digite o nome de usuário",
      },
      tw: {
        id: "tw",
        name: "Twitter",
        icon: "mdi-twitter",
        urlFormatter: (url) => `https://twitter.com/${url}`,
        prefix: "@",
        placeholder: "Digite o nome de usuário",
      },
      yt: {
        id: "yt",
        name: "Youtube",
        icon: "mdi-youtube",
        urlFormatter: (url) => url,
        prefix: "",
        placeholder: "Digite o link do canal",
      },
      tt: {
        id: "tt",
        name: "TikTok",
        icon: null,
        urlFormatter: (url) => `https://tiktok.com/@${url}`,
        prefix: "@",
        placeholder: "Digite o nome de usuário",
      },
    },
  }),
  computed: {
    hiddenAppBar() {
      return this.$route.meta.hiddenAppBar || false;
    },
    formattedWhatsApp() {
      const number = this.whatsapp.replace(/\D/g, "");
      return `55${number}`;
    },
  },
};
</script>
